<template>
    <v-app id="inspire">
        <router-view />
        <v-snackbar :value="updateExists" :timeout="-1" >
            Uma atualização está disponível
            <template v-slot:action="{ attrs }">
                <v-btn text @click="refreshApp">
                    Atualizar
                </v-btn>
            </template>
        </v-snackbar>

        <notifications class="notification-area"  >
            <template slot="body" slot-scope="props">
                <v-alert ref="alert" type="info" :type="props.item.type">
                    <h3 class="title" v-if="props.item.title">{{props.item.title}}</h3>
                    {{props.item.text}}
                </v-alert>
            </template>
        </notifications>

        <notifications group="notifcacao-error" class="notification-area" >
            <template slot="body" slot-scope="props">
                <v-alert ref="notificacaoAlert" color="#D50000" @click="showNotificationArea(props.item.id)"
                         dark style="cursor: pointer"
                         icon="mdi-alert"
                         border="right">
                    <h3 class="title" v-if="props.item.title">{{props.item.title}}</h3>
                    {{props.item.text}}
                </v-alert>
            </template>
        </notifications>

        <notifications group="tarefa-log-error" position="bottom center"
                       width="500px"
                       class="notification-area"  >
            <template slot="body" slot-scope="props">
                <v-alert ref="alert" type="error" dismissible>
                    <v-row align="center" no-gutters>
                        <v-col class="grow">
                            <span class="d-block body-2">Erro ao processar tarefa:</span>
                            <b>{{props.item.data.nome}}</b> às {{props.item.data.fim | moment('HH:mm:ss')}}
                        </v-col>
                        <v-col class="shrink">
                            <v-btn small color="white" light @click="showLogDialog(props)">Ver log</v-btn>
                        </v-col>
                    </v-row>

                </v-alert>
            </template>
        </notifications>
        <notifications group="tarefa-relatorio" position="bottom center"
                       width="500px"
                       class="notification-area"  >
            <template slot="body" slot-scope="props">
                <v-alert ref="alert" type="success" dismissible>
                    <v-row align="center" no-gutters>
                        <v-col class="grow">
                            <span class="d-block body-2">Tarefa concluída com sucesso:</span>
                            <b>{{props.item.data.nome}}</b> às {{props.item.data.fim | moment('HH:mm:ss')}}
                        </v-col>
                        <v-col class="shrink">
                            <v-btn small color="white" light @click="showResumoDialog(props)">Ver Relatório</v-btn>
                        </v-col>
                    </v-row>

                </v-alert>
            </template>
        </notifications>
        <tarefa-log-dialog ref="tarefaLogDialog" />
        <importar-arquivos-relatorio-dialog ref="importarArquivosRelatorioDialog" />
        <envia-laudos-banco-relatorio-dialog ref="enviarLaudosBancoRelatorioDialog" />
        <import-operacoes-relatorio-dialog ref="importOperacoesRelatorioDialog" />
        <baixa-extrato-bancario-relatorio-dialog ref="baixaExtratoBancarioRelatorioDialog" />
        <processa-coordenadas-relatorio-dialog ref="processaCoordenadasRelatorioDialog" />
    </v-app>
</template>

<script>
    import {AuthService} from "@/service/AuthService";
    import TarefaLogDialog from "@/views/sistema/TarefaLogDialog";
    import ImportarArquivosRelatorioDialog from "@/views/sistema/ImportarArquivosRelatorioDialog";
    import EnviaLaudosBancoRelatorioDialog from "@/views/sistema/EnviaLaudosBancoRelatorioDialog";
    import ImportOperacoesRelatorioDialog from "@/views/sistema/ImportOperacaoesRelatorioDialog";
    import BaixaExtratoBancarioRelatorioDialog from "@/views/sistema/BaixaExtratoBancarioRelatorioDialog";
    import ProcessaCoordenadasRelatorioDialog from "@/views/sistema/ProcessaCoordenadasRelatorioDialog";

    export default {
        name: 'App',
        components: {
            ProcessaCoordenadasRelatorioDialog,
            BaixaExtratoBancarioRelatorioDialog,
            ImportOperacoesRelatorioDialog,
            EnviaLaudosBancoRelatorioDialog,
            ImportarArquivosRelatorioDialog,
            TarefaLogDialog
        },
        data(){
            return {
                refreshing: false,
                registration: null,
                updateExists: false,
            }
        },
        watch: {
            $route(to, from) {
                document.title = to.meta.title || document.title;
            },
        },
        created() {
            // Listen for our custom event from the SW registration
            document.addEventListener('swUpdated', this.updateAvailable, { once: true })

            // Prevent multiple refreshes
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (this.refreshing) return
                this.refreshing = true
                // Here the actual reload of the page occurs
                window.location.reload()
            })
        },
        async mounted() {
            const currentUser = await AuthService.getCurrentUser();
            if (!currentUser) {
                await this.$router.replace({path: '/login'})
            }
        },
        methods: {
            updateAvailable(event) {
                this.registration = event.detail
                this.updateExists = true
            },

            // Called when the user accepts the update
            refreshApp() {
                this.updateExists = false
                // Make sure we only send a 'skip waiting' message if the SW is waiting
                if (!this.registration || !this.registration.waiting) return
                // send message to SW to skip the waiting and activate the new SW
                this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
            },
            showLogDialog(props){
                let tarefa = props.item.data;
                this.$refs.tarefaLogDialog.openDialog(tarefa.id);
                props.close();
            },
            showResumoDialog(props){
                let tarefa = props.item.data;
                switch (tarefa.tipo) {
                    case "importa-arquivos":
                        this.$refs.importarArquivosRelatorioDialog.openDialog(tarefa.id);
                        break;
                    case "envia-laudos-banco":
                        this.$refs.enviarLaudosBancoRelatorioDialog.openDialog(tarefa.id);
                        break;
                    case "import-operacoes":
                        this.$refs.importOperacoesRelatorioDialog.openDialog(tarefa.id);
                        break;
                    case "baixa-extrato-bancario":
                        this.$refs.baixaExtratoBancarioRelatorioDialog.openDialog(tarefa.id);
                        break;
                    case "processa-coordenadas":
                        this.$refs.processaCoordenadasRelatorioDialog.openDialog(tarefa.id);
                        break;
                }

                props.close();
            },
            showNotificationArea(id){
                this.$notify.close(id)
                this.$root.$emit('openNotificationArea');
            }
        }
    };
</script>

<style>
    .notification-area{
        margin-top: 84px;
        margin-right: 10px;
    }
</style>
