import { render, staticRenderFns } from "./BaixaExtratoBancarioRelatorioDialog.vue?vue&type=template&id=5547ff81&scoped=true&"
import script from "./BaixaExtratoBancarioRelatorioDialog.vue?vue&type=script&lang=js&"
export * from "./BaixaExtratoBancarioRelatorioDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5547ff81",
  null
  
)

export default component.exports