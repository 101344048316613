/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue';
// import VueAnalytics from 'vue-analytics'
import VueRouter from 'vue-router';
// import Meta from 'vue-meta'

import routes from './routes';

Vue.use(VueRouter);

// Create a new router
const router = new VueRouter({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { selector: to.hash };
        }
        return { x: 0, y: 0 };
    },
    routes,
});

export default router;
