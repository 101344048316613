import Vue from 'vue';
import vuetify from '@/plugins/vuetify';  // path to vuetify export

import App from './App.vue';
import router from '@/router';
import store from '@/store/index';
import './registerServiceWorker';
import axios from '@/axios';

Vue.prototype.$env = process.env;

import { createProvider } from './vue-apollo';

Vue.config.productionTip = false;

/* eslint-disable no-new */
Vue.prototype.$axios = axios;

createProvider().then((provider) => {
    new Vue({
        vuetify,
        // i18n,
        router,
        store,
        apolloProvider: provider,
        render: (h) => h(App),
    }).$mount('#app');
});
