import Axios, {AxiosStatic} from 'axios';
import router from '@/router';
import {AuthService} from '@/service/AuthService';

const axiosInstance = Axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT,
    // 'X-Requested-With': 'XMLHttpRequest',
});

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error:any, token:string|null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

function customSuccessResponse(response:any) {
    return response;
}

function customErrorResponse(error:any) {
    const originalRequest = error.config;

    if (error.response && error.config.url !== error.config.baseURL + 'oauth/token') {
        if (error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    failedQueue.push({resolve, reject});
                }).then((token) => {
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    return axiosInstance(originalRequest);
                }).catch((err) => {
                    return err;
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise((resolve, reject) => {
                AuthService.refreshToken().then((credential) => {
                    let accessToken = credential.accessToken;
                    axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
                    originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;
                    processQueue(null, accessToken);
                    resolve(axiosInstance(originalRequest));
                }).catch((err) => {
                    // TODO: Limpar o token aqui e redirecionar para a tela de login
                    processQueue(err, null);

                    AuthService.clearCredentials();
                    router.replace('/login');

                    reject(err);
                })
                    .then(() => { isRefreshing = false; });
            });
        }
    }

    return Promise.reject(error);
}

axiosInstance.interceptors.response.use(customSuccessResponse, customErrorResponse);

axiosInstance.interceptors.request.use(async (config) => {
    if (await AuthService.getAccessToken()) {
        config.headers.common['Authorization'] = 'Bearer ' + await AuthService.getAccessToken();
    } else {
        AuthService.clearCredentials();
        router.replace('/login')
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default axiosInstance;

declare module 'vue/types/vue' {
    interface Vue {
        $axios: AxiosStatic;
    }
}