<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="900px">
        <v-card min-height="500px">
            <v-card-title>
                <span class="headline" style="width: 100%">Relatório</span>
            </v-card-title>
            <v-card-text class="">
                <div v-if="tarefa" style="position: sticky; top:0; background-color: white; z-index: 2">
                    <v-row no-gutters class="mb-1 caption">
                        <v-col>
                            Nome: <span class="font-weight-medium">{{tarefa.nome}}</span>
                        </v-col>
                        <v-col class="text-right">
                            Disparado por: <span class="font-weight-medium">{{tarefa.disparadoPor ? tarefa.disparadoPor.nome : 'Automático'}}</span>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mb-1 caption">
                        <v-col cols="4">
                            Iniciado: <span class="font-weight-medium">{{tarefa.inicio | moment("DD/MM/YYYY HH:mm:ss")}}</span>
                        </v-col>
                        <v-col cols="4" class="text-center">
                            Finalizado: <span class="font-weight-medium">{{tarefa.fim | moment("DD/MM/YYYY HH:mm:ss")}}</span>
                        </v-col>
                        <v-col cols="4" class="text-right">
                            Duração: <span class="font-weight-medium">{{formatDuracao(tarefa)}}</span>
                        </v-col>
                    </v-row>
                </div>
                <v-simple-table v-if="transacoes && transacoes.length > 0">
                    <template v-slot:default>
                        <thead>
                        <tr style="white-space: nowrap">
                            <th class="text-left">Data</th>
                            <th class="text-left">Lançamento</th>
                            <th class="text-left">Dcto.</th>
                            <th class="text-left" >Valor</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(transacao, index) in transacoes">
                            <tr :key="transacao.id" style="white-space: nowrap">
                                <td class="grey--text darken-3 caption">
                                    <span>{{ transacao.dtposted | moment("DD/MM/YYYY HH:mm") }}</span>
                                </td>
                                <td>{{transacao.memo}}</td>
                                <td>{{transacao.checknum}}</td>
                                <td :class="transacao.trnamt > 0 ? 'indigo--text' : 'red--text'">
                                    <span>{{transacao.trnamt | formatNumber}}</span>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </template>
                </v-simple-table>
                <div v-else class="text-center pa-6">
                    <span class="headline font-weight-light">Nenhum resultado!</span>
                </div>
            </v-card-text>

            <v-card-actions class="">
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Fechar</v-btn>
                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "BaixaExtratoBancarioRelatorioDialog",
    data() {
        return {
            dialog: false,
            loading: false,
            tarefa: null,
            transacoes: [],
        }
    },
    methods: {
        openDialog(tarefaLogId) {
            this.dialog = true;
            this.transacoes = [];
            this.getResultado(tarefaLogId)
        },
        closeDialog() {
            this.dialog = false;
        },
        getResultado(tarefaLogId){
            this.loading = true;
            this.$apollo.query({
                query: gql`query($tarefaLogId:Int!) {
                    tarefa:tarefaLog(id: $tarefaLogId){
                        id
                        nome
                        inicio
                        fim
                        disparadoPor{
                            nome
                        }
                    }
                    resultado:resultadoTarefaBaixaExtratoBancario(tarefaLogId: $tarefaLogId){
                        transacoesAdicionadas{
                            id
                            memo
                            trnamt
                            dtposted
                            checknum
                            ignored
                            conta
                        }
                    }
                }`,
                variables: {
                    tarefaLogId: tarefaLogId
                }
            }).then((result) => {
                let resultado = result.data.resultado;
                this.tarefa = result.data.tarefa;
                this.transacoes = resultado.transacoesAdicionadas;
            }).finally(() => {
                this.loading = false;
            })
        },
        formatDuracao(tarefa){
            let formatInt = (int) => {
                if (int < 10) {
                    return `0${int}`;
                }
                return `${int}`;
            };

            let formatDuration = (time) => {
                const seconds = this.$moment.duration(time).seconds();
                const minutes = this.$moment.duration(time).minutes();
                const hours = this.$moment.duration(time).hours();
                if (hours > 0) {
                    return `${formatInt(hours)}:${formatInt(minutes)}:${formatInt(seconds)} horas`;
                }
                if (minutes > 0) {
                    return `${formatInt(minutes)}:${formatInt(seconds)} min`;
                }
                return `${formatInt(seconds)} seg`;
            };
            if(tarefa.fim){
                return formatDuration(this.$moment.duration(this.$moment(tarefa.fim).diff(this.$moment(tarefa.inicio))))
            }
            return null;
        },
    }
}
</script>

<style scoped>

</style>