export class UserCredential {
    accessToken:string;
    refreshToken:string;
    expiresAt:number;
    scope:string;

    constructor(json:any) {
        this.accessToken = json.access_token;
        this.refreshToken = json.refresh_token;
        this.expiresAt = new Date().setTime(new Date().getTime() + (json.expires_in * 1000));
        this.scope = json.scope;
    }
}