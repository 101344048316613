<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="900px">
        <v-card min-height="500px">
            <v-card-title>
                <span class="headline" style="width: 100%">Relatório</span>
            </v-card-title>
            <v-card-text class="">
                <div v-if="tarefa" style="position: sticky; top:0; background-color: white; z-index: 2">
                    <v-row no-gutters class="mb-1 caption">
                        <v-col>
                            Nome: <span class="font-weight-medium">{{tarefa.nome}}</span>
                        </v-col>
                        <v-col class="text-right">
                            Disparado por: <span class="font-weight-medium">{{tarefa.disparadoPor ? tarefa.disparadoPor.nome : 'Automático'}}</span>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mb-1 caption">
                        <v-col cols="4">
                            Iniciado: <span class="font-weight-medium">{{tarefa.inicio | moment("DD/MM/YYYY HH:mm:ss")}}</span>
                        </v-col>
                        <v-col cols="4" class="text-center">
                            Finalizado: <span class="font-weight-medium">{{tarefa.fim | moment("DD/MM/YYYY HH:mm:ss")}}</span>
                        </v-col>
                        <v-col cols="4" class="text-right">
                            Duração: <span class="font-weight-medium">{{formatDuracao(tarefa)}}</span>
                        </v-col>
                    </v-row>
                    <v-tabs grow v-model="tab" style="">
                        <v-tab>
                            <v-badge color="red" tile
                                     :value="fiscalizacoesComCoordenadas.length > 0"
                                     :content="fiscalizacoesComCoordenadas.length">
                                Com Coordenadas
                            </v-badge>
                        </v-tab>
                        <v-tab>
                            <v-badge color="red" tile
                                     :value="fiscalizacoesSemCoordenadas.length > 0"
                                     :content="fiscalizacoesSemCoordenadas.length">
                                Sem Coordenadas
                            </v-badge>
                        </v-tab>
                        <v-tab>
                            <v-badge color="red" tile
                                     :value="fiscalizacoesNaoEncontradas.length > 0"
                                     :content="fiscalizacoesNaoEncontradas.length">
                                Não encontradas
                            </v-badge>
                        </v-tab>
                    </v-tabs>
                </div>

                <template v-if="tab === 0">
                    <v-list  v-if="fiscalizacoesComCoordenadas.length > 0">
                        <template v-for="(fiscalizacao) in fiscalizacoesComCoordenadas">
                            <v-list-item :key="fiscalizacao.id" :to="{ name: 'fiscalizacao_view', params: { id: parseInt(fiscalizacao.id) }}">
                                <v-list-item-avatar>
                                    <v-icon class="light-green lighten-3" v-text="getItemIcon(fiscalizacao.tipo)"></v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{fiscalizacao.financiamento.mutuario}}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        <v-list-item-subtitle>
                                            N. Solicitação: {{fiscalizacao.numeroSolicitacao}} Operação: {{fiscalizacao.financiamento.operacao+(fiscalizacao.financiamento.operacaoDigito ? '-'+fiscalizacao.financiamento.operacaoDigito : '')}}
                                        </v-list-item-subtitle>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider ></v-divider>
                        </template>
                    </v-list>
                    <div v-else class="text-center pa-6">
                        <span class="headline font-weight-light">Nenhum resultado!</span>
                    </div>
                </template>
                <template v-if="tab === 1">
                    <v-list v-if="fiscalizacoesSemCoordenadas.length > 0">
                        <template v-for="(fiscalizacao) in fiscalizacoesSemCoordenadas">
                            <v-list-item :key="fiscalizacao.id" :to="{ name: 'fiscalizacao_view', params: { id: parseInt(fiscalizacao.id) }}">
                                <v-list-item-avatar>
                                    <v-icon class="light-green lighten-3" v-text="getItemIcon(fiscalizacao.tipo)"></v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{fiscalizacao.financiamento.mutuario}}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        <v-list-item-subtitle>
                                            N. Solicitação: {{fiscalizacao.numeroSolicitacao}} Operação: {{fiscalizacao.financiamento.operacao+(fiscalizacao.financiamento.operacaoDigito ? '-'+fiscalizacao.financiamento.operacaoDigito : '')}}
                                        </v-list-item-subtitle>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider ></v-divider>
                        </template>
                    </v-list>
                    <div v-else class="text-center pa-6">
                        <span class="headline font-weight-light">Nenhum resultado!</span>
                    </div>
                </template>
                <template v-if="tab === 2">
                    <v-list v-if="fiscalizacoesNaoEncontradas.length > 0">
                        <template v-for="(fiscalizacao) in fiscalizacoesNaoEncontradas">
                            <v-list-item :key="fiscalizacao.id" :to="{ name: 'fiscalizacao_view', params: { id: parseInt(fiscalizacao.id) }}">
                                <v-list-item-avatar>
                                    <v-icon class="light-green lighten-3" v-text="getItemIcon(fiscalizacao.tipo)"></v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{fiscalizacao.financiamento.mutuario}}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        N. Solicitação: {{fiscalizacao.numeroSolicitacao}} Operação: {{fiscalizacao.financiamento.operacao+(fiscalizacao.financiamento.operacaoDigito ? '-'+fiscalizacao.financiamento.operacaoDigito : '')}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider ></v-divider>
                        </template>
                    </v-list>
                    <div v-else class="text-center pa-6">
                        <span class="headline font-weight-light">Nenhum resultado!</span>
                    </div>
                </template>
            </v-card-text>

            <v-card-actions class="">
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Fechar</v-btn>
                    <div class="float-right">
                        <v-btn text color="primary"
                               :disabled="!canDownloadResultado"
                               @click="downloadResultadoXls">Gerar relatório</v-btn>
                    </div>
                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import {getIconByFiscalizacaoTipo} from "../../assets/FiscaizacaoUtil";
export default {
    name: "ProcessaCoordenadasRelatorioDialog",
    data() {
        return {
            dialog: false,
            loading: false,
            tarefa: null,
            tab: null,
            fiscalizacoesNaoEncontradas: [],
            fiscalizacoesComCoordenadas: [],
            fiscalizacoesSemCoordenadas: [],
            canDownloadResultado: false,
        }
    },
    watch:{
        tab(value){
            if(value === 0){
                this.canDownloadResultado = this.fiscalizacoesComCoordenadas.length > 0;
            }else if(value === 1){
                this.canDownloadResultado = this.fiscalizacoesSemCoordenadas.length > 0;
            }else if(value === 2){
                this.canDownloadResultado = this.fiscalizacoesNaoEncontradas.length > 0;
            }else{
                this.canDownloadResultado = false;
            }
        }
    },
    methods: {
        openDialog(tarefaLogId) {
            this.dialog = true;
            this.fiscalizacoesNaoEncontradas = [];
            this.fiscalizacoesComCoordenadas = [];
            this.fiscalizacoesSemCoordenadas = [];
            this.getResultado(tarefaLogId)
        },
        closeDialog() {
            this.dialog = false;
        },
        getResultado(id){
            this.loading = true;
            this.$apollo.query({
                query: gql`query ($tarefaLogId: Int!){
                    tarefa:tarefaLog(id: $tarefaLogId){
                        id
                        nome
                        inicio
                        fim
                        disparadoPor{
                            nome
                        }
                    }
                    resultado:resultadoProcessaCoordenadas(tarefaLogId: $tarefaLogId){
                        fiscalizacoesNaoEncontradas{
                            id
                            numeroSolicitacao
                            tipo{
                                nome
                            }
                            financiamento{
                                mutuario
                                cedula
                                operacao
                                operacaoDigito
                            }
                        }
                        fiscalizacoesComCoordenadas{
                            id
                            numeroSolicitacao
                            tipo{
                                nome
                            }
                            financiamento{
                                mutuario
                                cedula
                                operacao
                                operacaoDigito
                            }
                        }
                        fiscalizacoesSemCoordenadas{
                            id
                            numeroSolicitacao
                            tipo{
                                nome
                            }
                            financiamento{
                                mutuario
                                cedula
                                operacao
                                operacaoDigito
                            }
                        }
                    }
                }
                `,
                variables: {
                    tarefaLogId: id,
                },
            }).then((result) => {
                let resultado = result.data.resultado;
                this.tarefa = result.data.tarefa;
                this.fiscalizacoesNaoEncontradas = resultado.fiscalizacoesNaoEncontradas;
                this.fiscalizacoesComCoordenadas = resultado.fiscalizacoesComCoordenadas;
                this.fiscalizacoesSemCoordenadas = resultado.fiscalizacoesSemCoordenadas;
            }).finally(() => {
                this.loading = false;
            })
        },
        getItemIcon(tipo){
            return getIconByFiscalizacaoTipo(tipo);
        },
        downloadResultadoXls(){
            let fiscalizacoesIds = null;

            if(this.tab === 0){
                fiscalizacoesIds = this.fiscalizacoesNaoEncontradas.map((f) => f.id);
            }

            if(this.tab === 1){
                fiscalizacoesIds = this.fiscalizacoesEnviadas.map((f) => f.id);
            }

            if(this.tab === 2){
                fiscalizacoesIds = this.fiscalizacoesNaoEnviadas.map((f) => f.id);
            }

            if(!fiscalizacoesIds){
                return
            }
            this.$axios.post(
                '/fiscalizacoes/resultado_fiscalizacoes/xls',
                fiscalizacoesIds,
                {responseType: 'blob'})
                .then((response) => {
                    const fileLink = document.createElement('a');
                    fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
                    const fileName = response.headers['content-disposition'].split('filename=')[1].replace(/^"|"$/g, '');
                    fileLink.setAttribute('download', fileName);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                    this.downloadingResumo = false;
                }).catch(() => {
                this.$dialog.error({
                    text: 'Não foi possível baixar o arquivo. Contate o desenvolvedor.',
                    title: 'Ops',
                });

            });
        },
        formatDuracao(tarefa){
            let formatInt = (int) => {
                if (int < 10) {
                    return `0${int}`;
                }
                return `${int}`;
            };

            let formatDuration = (time) => {
                const seconds = this.$moment.duration(time).seconds();
                const minutes = this.$moment.duration(time).minutes();
                const hours = this.$moment.duration(time).hours();
                if (hours > 0) {
                    return `${formatInt(hours)}:${formatInt(minutes)}:${formatInt(seconds)} horas`;
                }
                if (minutes > 0) {
                    return `${formatInt(minutes)}:${formatInt(seconds)} min`;
                }
                return `${formatInt(seconds)} seg`;
            };
            if(tarefa.fim){
                return formatDuration(this.$moment.duration(this.$moment(tarefa.fim).diff(this.$moment(tarefa.inicio))))
            }
            return null;
        },
    }
}
</script>

<style scoped>

</style>