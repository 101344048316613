import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import ValidationProvider from 'vee-validate';
import VuetifyDialog from 'vuetify-dialog';
import 'vuetify-dialog/dist/vuetify-dialog.css';
import VueTheMask from 'vue-the-mask';
import money from 'v-money';
import VueLodash from 'vue-lodash';
import lodash from 'lodash'
import Notifications from 'vue-notification';
import numeral from 'numeral';
import PortalVue from 'portal-vue'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
// @ts-ignore
const moment = require('moment-business-days');
// @ts-ignore
require('moment/locale/pt-br');

// @ts-ignore
Vue.use(require('vue-moment'), {
    moment,
});

Vue.use(PortalVue)
Vue.use(Notifications);
Vue.use(VueLodash, {lodash});
Vue.use(Vuetify);
Vue.use(VueTheMask);
Vue.use(money, {precision: 2, decimal: ',', thousands: '.'});



Vue.component('ValidationProvider', ValidationProvider);

numeral.register('locale', 'pt-br', {
    delimiters: {
        thousands: '.',
        decimal: ',',
        // precision: 2
    },
    currency: {
        symbol: 'R$',
    },
});

Vue.filter('formatNumber', (value) => {
    numeral.locale('pt-br');
    return numeral(value).format('$ 0,0.00');
});

Vue.filter('formatDecimal', (value) => {
    numeral.locale('pt-br');
    return numeral(value).format('0,0.00');
});

const vuetify =  new Vuetify({
    //TODO: Precisa desse pra tradizir o wisywyg, mas fica disparando warning no console pq ptbr não existe no vuetify, só pt
    /*lang: {
        current: 'ptbr'
    },*/
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
                primary: '#519657',
                secondary: '#c70500',
                accent: '#f4511e',
            },
        },
    },
});

Vue.use(TiptapVuetifyPlugin, {
    // the next line is important! You need to provide the Vuetify Object to this place.
    vuetify, // same as "vuetify: vuetify"
    // optional, default to 'md' (default vuetify icons before v2.0.0)
    iconsGroup: 'md'
})

Vue.use(VuetifyDialog, {
    context: {
        vuetify,
    },
    confirm: {
        showClose: false,
        actions: {
            false: 'Cancelar',
            yes: {
                text: 'Sim',
                color: 'primary',
            },
        },
    },
    warning: {
        showClose: false,
        actions: {
            false: 'Cancelar',
        },
    },
    error: {
        showClose: false,
        actions: {
            false: 'Cancelar',
        },
    },
});

export default vuetify;

