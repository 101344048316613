import Vue from 'vue'
import Vuex from 'vuex'
import lembrete from "@/store/modules/lembrete";

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        lembrete,
    },
    strict: debug,
    //plugins: debug ? [createLogger()] : []
})