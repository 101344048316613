import gql from "graphql-tag";
import {apolloClient} from "@/apollo/client"


const state = () => ({
    total: 0,
})

// getters
const getters = {
    /*totalLembretes: (state, getters) => {
        return state.total;
    }*/
}

// actions
const actions = {
    getTotalLembretes({commit}){
        apolloClient.query({
            query: gql`query {
                totalLembretes
            }`,
        }).then(result => {
            commit("setTotalLembretes", result.data.totalLembretes)
        })
    }
}

// mutations
const mutations = {
    setTotalLembretes(state, value){
        state.total = value
    },
    incrementTotalLembretes(state){
        state.total++;
    },
    decreaseTotalLembretes(state){
        state.total--;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}