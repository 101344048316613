import {ApolloClient} from "apollo-client";
import {ApolloLink, from, Observable, split} from "apollo-link";
import {InMemoryCache} from "apollo-cache-inmemory";
import {setContext} from "apollo-link-context";
import {AuthService} from "@/service/AuthService";
import {createHttpLink} from "apollo-link-http";
import {SubscriptionClient} from "subscriptions-transport-ws";
import {onError} from "apollo-link-error";
import Vue from "vue";
import router from "@/router";
import {getMainDefinition} from "apollo-utilities";
import {WebSocketLink} from "apollo-link-ws";
import {ServerError} from "@apollo/client/link/utils/throwServerError";

//const authLink = setContext(async (_, { headers }) => {
const authLink = setContext(async (_, { headers }) => {
    // return the headers to the context so httpLink can read them
    const token = await AuthService.getAccessToken();
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        },
    };
});

// HTTP connection to the API
const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: process.env.VUE_APP_API_ENDPOINT + "graphql",
});

const uri = process.env.VUE_APP_API_WS_ENDPOINT + "subscriptions";
const wsLink = new WebSocketLink({
    uri,
    options: {
        reconnect: true,
        connectionParams: async () => {
            return {
                authToken: AuthService.getAccessToken(),
            };
        },
        /*connectionParams: {
            authToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiYXBpIl0sImV4cCI6MTU5OTcxOTU1NCwidXNlcl9uYW1lIjoiZGFuaWxvQGpmYXNzZXNzb3JpYXJ1cmFsLmNvbS5iciIsImp0aSI6IjkyMDcxNzJjLWRhM2MtNDc5ZC04YzdmLTdhNjdjMTkxNzlhNCIsImNsaWVudF9pZCI6ImpmYWRtaW5fY2xpZW50Iiwic2NvcGUiOlsicmVhZCIsIndyaXRlIl19.1GruFXMEWIjSa9P77qqSF3FttKDRupUkOc-Qah2HCNw",
        },*/
    },
})
/*const wsLink = new SubscriptionClient(uri, {
    reconnect: true,
    lazy: true,
    connectionParams: async () => {
        return {
            access_token: AuthService.getAccessToken(),
        };
    },
});

wsLink.onConnecting(args => {
    console.log('onConnecting', args)
});

wsLink.onConnected(args => {
    console.log('onConnected', args)
});

wsLink.onError(args => {
    //args.target.url = `${uri}?access_token=${count}`;
    console.log('onError', args)
}, this);*/

let isRefreshing:boolean = false;
let pendingRequests = [];

const resolvePendingRequests = () => {
    pendingRequests.map(callback => callback());
    pendingRequests = [];
};

const errorLink = onError(({response, forward, operation, graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        /*console.log("aqio", graphQLErrors)
        for (let err of graphQLErrors) {
            switch (err.extensions.code) {
                case 'UNAUTHENTICATED':
                    let forward$;
                    if(!isRefreshing){
                        isRefreshing = true;
                        forward$ = fromPromise(
                            AuthService.refreshToken()
                                .then((credential:UserCredential) => {
                                    resolvePendingRequests();
                                    return credential.accessToken;
                                })
                                .catch(error => {
                                    pendingRequests = [];
                                    // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
                                    return;
                                })
                                .finally(() => {
                                    isRefreshing = false;
                                })
                        ).filter(value => Boolean(value))
                    }else{
                        forward$ = fromPromise(
                            new Promise(resolve => {
                                pendingRequests.push(() => resolve());
                            })
                        )
                    }

                    return forward$.flatMap(() => forward(operation))
            }
        }*/
        graphQLErrors.map(({message, locations, path}) =>
                console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
            //Vue.prototype.$dialog.message.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
        );
    }

    if (networkError) {
        //Vue.prototype.$dialog.message.error(`[Network error]: ${networkError}`);
        // console.log("networkError", networkError)
        // console.log("networkError.message", networkError.message)
        // console.log("networkError.name", networkError.name)
        // console.log("networkError.statusCode", networkError.statusCode)
        // console.log("networkError.response", networkError.response)

        //TODO: Acho q essa logica do 401 vai ter que ir pra outro lugar, pq aqui ta estourando um erro por não ter a propriedade response quando entra aqui

        /*console.log("sera que ta dando erro aqui")
        console.log(networkError)
        console.log(networkError.response)
        console.log(networkError.response.status)*/
        let serverError = networkError as ServerError;
        if (serverError.response !== undefined && serverError.response.status === 401) {
            return new Observable(observer => {
                AuthService.refreshToken().then((credential) => {
                    operation.setContext(({headers = {}}) => ({
                        headers: {
                            ...headers,
                            authorization: `Bearer ${credential.accessToken}`,
                        },
                    }));
                }).then(() => {
                    forward(operation).subscribe(observer);
                })
                    .catch(error => {
                        observer.error(error);
                        AuthService.clearCredentials();
                        router.replace('/login');
                    });
            });
        } else {
            Vue.prototype.$dialog.message.error(`[Network error]: ${serverError}`);
        }
    }
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
    // split based on operation type
    ({query}) => {
        // @ts-ignore
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
    },
    wsLink,
    //new WebSocketLink(wsLink),
    httpLink,
);

// Cache implementation
const cache = new InMemoryCache();

const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
        const omitTypename = (key:string, value:any) => (key === '__typename' ? undefined : value);
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward(operation).map((data) => {
        return data;
    });
});


// Create the apollo client
export const apolloClient = new ApolloClient({
    //link: from([errorLink, authLink, link]),
    link: from([cleanTypeName, errorLink, authLink, link]),
    cache,
    defaultOptions: {
        query: {
            fetchPolicy: "no-cache",
        },
    },
    connectToDevTools: true,
});