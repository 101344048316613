<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="900px">
        <v-card v-if="dialog" >
            <v-card-title>
                <span class="headline">Log</span>
            </v-card-title>
            <v-card-text class="pb-0" v-if="tarefa">
                <v-row no-gutters class="mb-1 caption">
                    <v-col>
                        Nome: <span class="font-weight-medium">{{tarefa.nome}}</span>
                    </v-col>
                    <v-col class="text-right">
                        Disparado por: <span class="font-weight-medium">{{tarefa.disparadoPor ? tarefa.disparadoPor.nome : 'Automático'}}</span>
                    </v-col>
                </v-row>
                <v-row no-gutters class="caption">
                    <v-col cols="4">
                        Iniciado: <span class="font-weight-medium">{{tarefa.inicio | moment("DD/MM/YYYY HH:mm:ss")}}</span>
                    </v-col>
                    <v-col cols="4" class="text-center">
                        Finalizado: <span class="font-weight-medium">{{tarefa.fim | moment("DD/MM/YYYY HH:mm:ss")}}</span>
                    </v-col>
                    <v-col cols="4" class="text-right">
                        Duração: <span class="font-weight-medium">{{formatDuracao(tarefa)}}</span>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-textarea label="Log" :value="tarefa.log.trim()" hide-details readonly outlined disabled
                                rows="5" class="mt-4 text-area-log"
                                style="font-family: monospace; font-size: 12px; " />
                    <!--<textarea style="resize: none; width: 100%; height:350px; font-family: monospace; font-size: 12px;"
                              disabled >{{tarefa.log.trim()}}</textarea>-->
                </v-row>

            </v-card-text>
            <v-card-actions class="">
                <div class="pa-3" style="width: 100%">
                    <div class="float-right">
                        <v-btn text color="primary"
                               @click="dialog = false">Fechar</v-btn>
                    </div>
                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "TarefaLogDialog",
    data() {
        return {
            dialog: false,
            loading: false,
            tarefa: null,
        }
    },
    methods: {
        openDialog(tarefaId) {
            this.dialog = true;
            //this.tarefa = tarefa;
            this.getLog(tarefaId);
        },
        getLog(tarefaLogId){
            this.loading = true;
            this.$apollo.query({
                query: gql`query($id:Int) {
                    tarefaLog(id: $id){
                        id
                        nome
                        inicio
                        fim
                        disparadoPor{
                            nome
                        }
                        log
                    }
                }`,
                variables: {
                    id: tarefaLogId
                }
            }).then((result) => {
                this.tarefa = result.data.tarefaLog
            }).finally(()=>{
                this.loading = false;
            })
        },
        formatDuracao(tarefa){
            let formatInt = (int) => {
                if (int < 10) {
                    return `0${int}`;
                }
                return `${int}`;
            };

            let formatDuration = (time) => {
                const seconds = this.$moment.duration(time).seconds();
                const minutes = this.$moment.duration(time).minutes();
                const hours = this.$moment.duration(time).hours();
                if (hours > 0) {
                    return `${formatInt(hours)}:${formatInt(minutes)}:${formatInt(seconds)} horas`;
                }
                if (minutes > 0) {
                    return `${formatInt(minutes)}:${formatInt(seconds)} min`;
                }
                return `${formatInt(seconds)} seg`;
            };
            if(tarefa.fim){
                return formatDuration(this.$moment.duration(this.$moment(tarefa.fim).diff(this.$moment(tarefa.inicio))))
            }
            return null;
        },
    }
}
</script>

<style >
    .text-area-log .v-text-field__slot textarea{


        margin-bottom: 10px;
    }
</style>