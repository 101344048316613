import localforage from 'localforage';
import {UserCredential} from "@/models/UserCredential";
import {Usuario} from "@/models/Usuario";
import {DollarApollo} from "vue-apollo/types/vue-apollo";
import gql from "graphql-tag";
import {ApolloQueryResult} from "apollo-client";
import {VueRouter} from "vue-router/types/router";
import {UsuarioQueries} from "@/queries/UsuarioQueries";

export class AuthService {
    private static URI = process.env.VUE_APP_API_ENDPOINT + 'oauth/token';
    private static CLIENT_ID = 'jfadmin_client';
    private static CLIENT_SECRET = 'jfadmin_secret';
    private static CREDENTIAL:UserCredential|null;
    private static CURRENT_USER:Usuario|null;

    public static async login(username: string, password: string) {
        const response = await this.fetchToken(username, password);

        if (response.ok) {
            const data = await response.json();
            await this.persistCredentials(new UserCredential(data));

            // @ts-ignore
            console.log(data);
            // @ts-ignore
            console.log(data.access_token);
            // @ts-ignore
            console.log(data.refresh_token);

            return Promise.resolve();
        } else {
            return Promise.reject(response);
        }
    }

    public static async logout(router:VueRouter){
        this.clearCredentials();
        await localforage.clear();
        await router.replace({path:'/login'});
    }

    public static refreshToken():Promise<UserCredential|null> {
        return new Promise<UserCredential>(async (resolve, reject) => {
            let refreshToken:string|null = await this.getRefreshToken();
            if(refreshToken == null){
                reject('refreshToken is null')
                return;
            }
            let response:Response = await this.fetchRefreshToken(refreshToken);
            if (response.ok) {
                const data = await response.json();
                if (data) {
                    let credential = new UserCredential(data);
                    await this.persistCredentials(credential);
                    resolve(credential);
                }
            }
            reject();
        })
    }

    public static fetchUsuario(apollo:DollarApollo<any>):Promise<Usuario> {
        return apollo.query<Usuario>({
            query: UsuarioQueries.getUsuarioInfo,
        }).then((result:ApolloQueryResult<any>) => {
            this.CURRENT_USER = result.data.usuarioInfo;
            return localforage.setItem<Usuario>('usuario', <Usuario>this.CURRENT_USER)
        })
    }

    private static fetchToken(username: string, password: string) {
        const headers = this.getHeaders();

        const body = new FormData();
        body.append('grant_type', 'password');
        body.append('username', username);
        body.append('password', password);

        return fetch(this.URI, {method: 'POST', headers, body });
    }

    public static fetchRefreshToken(refreshToken: string):Promise<Response> {
        const headers = this.getHeaders();

        const body = new FormData();
        body.append('grant_type', 'refresh_token');
        body.append('refresh_token', refreshToken);

        return fetch(this.URI, {method: 'POST', headers, body });
    }

    public static async persistCredentials(credential:UserCredential):Promise<UserCredential> {
        this.CREDENTIAL = credential;
        await localforage.setItem<UserCredential>('credential', credential);
        return credential;
    }

    public static clearCredentials() {
        this.CREDENTIAL = null;
        this.CURRENT_USER = null;
        localforage.removeItem('credential')
        localforage.removeItem('usuario')
    }

    private static getHeaders(): Headers {
        const headers = new Headers();
        headers.append('Authorization', 'Basic ' + btoa(this.CLIENT_ID + ':' + this.CLIENT_SECRET));

        return headers;
    }

    public static async getCurrentUser():Promise<Usuario>{
        if(!this.CURRENT_USER){
            this.CURRENT_USER = await localforage.getItem<Usuario>('usuario');
        }
        return this.CURRENT_USER;
    }

    public static async getCredential():Promise<UserCredential>{
        if(!this.CREDENTIAL){
            this.CREDENTIAL = await localforage.getItem<UserCredential>('credential');
        }
        return this.CREDENTIAL;
    }

    public static async getAccessToken():Promise<string|null>{
        let credential:UserCredential = await this.getCredential()
        if(credential){
            return credential.accessToken;
        }
        return null;
    }

    public static async getRefreshToken():Promise<string|null>{
        let credential:UserCredential = await this.getCredential()
        if(credential) {
            return credential.refreshToken;
        }
        return null;
    }

}