import gql from 'graphql-tag';

export class UsuarioQueries {
    public static getUsuarioInfo = gql`query {
        usuarioInfo{
            id
            nome
            sobrenome
            email
            createdAt
            active
            admin
            resetPassword
            imagem{
                id
            }
        }
    }`;

    public static save = gql`mutation ($usuario:UsuarioInput!){
        saveUsuario(data: $usuario){
            id
            nome
            sobrenome
            email
            admin
            active
            createdAt
            updatedAt
            imagem{
                id
            }
        }
    }`;

    public static update = gql`mutation ($id:Int!, $usuario:UsuarioInput!){
        updateUsuario(id: $id, data: $usuario){
            id
            nome
            sobrenome
            email
            admin
            active
            createdAt
            updatedAt
            imagem{
                id
            }
        }
    }`;

    public static updatePassword = gql`mutation ($oldPassword:String!, $newPassword:String!){
        updateUsuarioPassword(old_password: $oldPassword, new_password: $newPassword){
            id
        }
    }`;

    public static newPassword = gql`mutation ($password:String!){
        newUsuarioPassword(password: $password){
            id
            nome
            sobrenome
            email
            admin
            createdAt
            updatedAt
            imagem{
                id
            }
        }
    }`;
}
