
const routes = [
    { path: '/index.html', component: () => import('../views/Index.vue') },
    { path: '/', component: () => import('../views/Index.vue') },
    { path: '/login', component: () => import('../views/Login.vue') },
    { name: 'newPassword', path: '/new_password', component: () => import('../views/NewPassword.vue') },
    { path: '/admin', meta: {title: 'Painel - JF Assessoria Rural'}, component: () => import('../layout/Admin.vue'),
        children: [
            { name: 'dashboard', path: '/', component: () => import('../views/dashboard/Index.vue')},
            { name: 'fiscalizacoes', path: 'fiscalizacao', component: () => import('../views/servico/fiscalizacao/Dashboard.vue')},
            { name: 'todas_fiscalizacoes', path: 'fiscalizacao/todas', component: () => import('../views/servico/fiscalizacao/Index.vue')},
            { name: 'fiscalizacao_view', path: 'fiscalizacao/:id', component: () => import('../views/servico/fiscalizacao/View.vue') },
            { name: 'projetos', path: 'projeto', component: () => import('../views/servico/projeto/Index.vue')},
            { name: 'projeto_view', path: 'projeto/:id', component: () => import('../views/servico/projeto/View.vue')},
            { name: 'usuarios', path: 'usuario', component: () => import('../views/sistema/usuario/Index.vue')},
            { name: 'notas_fiscais', path: 'financeiro/nota_fiscal', component: () => import('../views/financeiro/nota_fiscal/Index.vue')},
            { name: 'nota_fiscal_lancamentos_futuros', path: 'financeiro/nota_fiscal/lancamentos_futuros', component: () => import('../views/financeiro/nota_fiscal/LancamentosFuturos.vue')},
            { name: 'nota_fiscal_view', path: 'financeiro/nota_fiscal/:id', component: () => import('../views/financeiro/nota_fiscal/View.vue')},
            { name: 'servicos', path: 'servico', component: () => import('../views/servico/fiscalizacao/servico/Index.vue')},
            { name: 'remessas', path: 'remessa', component: () => import('../views/financeiro/conta/remessa/Index.vue')},
            { name: 'remessa_view', path: 'remessa/:id', component: () => import('../views/financeiro/conta/remessa/View.vue')},
            { name: 'retornos', path: 'retorno', component: () => import('../views/financeiro/conta/retorno/Index.vue')},
            { name: 'retorno_view', path: 'retorno/:id', component: () => import('../views/financeiro/conta/retorno/View.vue')},
            { name: 'lembretes', path: 'lembrete', component: () => import('../views/lembrete/Index.vue')},
            { name: 'agencias', path: 'agencia', component: () => import('../views/recursos/agencia/Index.vue')},
            { name: 'agencia_view', path: 'agencia/:id', component: () => import('../views/recursos/agencia/View.vue')},
            { name: 'cidades', path: 'cidade', component: () => import('../views/recursos/cidade/Index.vue')},
            { name: 'cidade_view', path: 'cidade/:id', component: () => import('../views/recursos/cidade/View.vue')},
            { name: 'tarefas', path: 'tarefa', component: () => import('../views/sistema/TarefasList.vue')},
            { name: 'emails', path: 'email/:grupo/:local/:id?', component: () => import('../views/email/Index.vue')},
            { name: 'financeiro', path: 'financeiro', component: () => import('../views/financeiro/View.vue')},
            { name: 'fechamento_view', path: 'financeiro/fechamento/:id', component: () => import('../views/financeiro/Index.vue')},
            { name: 'conta', path: 'financeiro/conta', component: () => import('../views/financeiro/conta/View.vue')},
            { name: 'conta_lancamentos', path: 'financeiro/conta/lancamentos', component: () => import('../views/financeiro/conta/Index.vue')},
            { name: 'conta_lancamentos_futuros', path: 'financeiro/conta/lancamentos_futuros', component: () => import('../views/financeiro/conta/LancamentosFuturos.vue')},
        ],
    },
];

export default routes;
