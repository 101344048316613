function getIconByFiscalizacaoTipo(tipo){
    if(!tipo){
        return 'mdi-help-circle-outline';
    }
    switch (tipo.nome.toLowerCase()) {
        case 'custeio agricola':
            return 'mdi-sprout';
        case 'custeio pecuário':
            return 'mdi-cow';
        case 'custeio piscicultura':
            return 'mdi-fish';
        case 'industrialização':
            return 'mdi-factory';
        case 'bndes':
            return 'mdi-bank';
        case 'investimento':
            return 'mdi-cash-register';
        default:
            return 'mdi-help-circle-outline'
    }
}

function getFinalizadoClass(fiscalizacao){
    let cls = ""
    if(fiscalizacao.dataFiscalizacao){
        cls += " v-list-item-finalizado"
        if(fiscalizacao.regular){
            cls += " finalizado-regular"
        }else{
            cls += " finalizado-irregular"
        }
    }
    return cls;
}

export {
    getIconByFiscalizacaoTipo,
    getFinalizadoClass
}
