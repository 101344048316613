import Vue from "vue";
import VueApollo from "vue-apollo";
import {apolloClient} from "@/apollo/client"

// Install the vue plugin
Vue.use(VueApollo);

// Call this in the Vue app file
export async function createProvider(options = {}) {

    // Create vue apollo provider
    const apolloProvider = new VueApollo({
        defaultClient: apolloClient,
    });

    return apolloProvider;
}
